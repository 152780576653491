/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
@charset 'UTF-8';

.organization-registration-details {
  --font-size: var(--font-size-body-extra-small);

  align-items: flex-start;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-quicksand-book);
  font-size: var(--font-size);
  gap: calc(var(--font-size) / 2);

  /* line-height: var(--font-size); */
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: 0;
  place-content: flex-start flex-start;
}

.organization-registration-details dt,
.organization-registration-details dd {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.organization-registration-details dt::after {
  content: ":";
}

/* ----------------------------------------------- */
dl.team-member-details,
dl.team-member-details dd {
  --gap: calc(var(--gutter) / 2);
}

dl.team-member-details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: var(--gap);
  padding-left: var(--gap);
  padding-right: var(--gap);
  padding-top: var(--gap);
  place-content: flex-start flex-start;
  row-gap: calc(var(--gap) / 2);
}

dl.team-member-details dt {
  display: none;
}

dl.team-member-details dd {
  --font-size: var(--font-size-body-fluid);

  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  width: 100%;
}

dl.team-member-details dd.name {
  --font-size: var(--font-size-h6-fluid);

  font-family: var(--font-family-quicksand-bold);
}

dl.team-member-details dd.job-title {
  line-height: var(--type-scale-major-second);
}

dl.team-member-details dd.about,
dl.team-member-details dd.social-networks {
  --font-size: var(--font-size-body-small);
}

dl.team-member-details dd.about {
  flex-grow: 1;
  line-height: var(--type-scale-golden-ratio);
}

dl.team-member-details dd.social-networks {
  --ruler-height: 1px;
  --ruler-opacity: calc(100% / 3);

  border-color: rgb(255 255 255 / var(--ruler-opacity));
  border-style: solid;
  border-width: var(--ruler-height) 0 0;
  padding-top: calc(var(--gap) / 2 + var(--ruler-height));
}

/* ----------------------------------------------- */
dl.team-member-details ul.social a,
dl.team-member-details ul.social button {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-950);

  box-shadow: var(--shadow-elevation-low);
}

dl.team-member-details ul.social a:hover,
dl.team-member-details ul.social button:hover {
  --surface-color: var(--color-cobalt-blue-100);
  --on-surface-color: var(--color-cobalt-blue-1000);

  box-shadow: var(--shadow-elevation-medium);
}

dl.team-member-details ul.social a:active,
dl.team-member-details ul.social a:focus,
dl.team-member-details ul.social button:active,
dl.team-member-details ul.social button:focus {
  --surface-color: var(--color-moss-green-300);
  --on-surface-color: var(--color-moss-green-1000);
}

dl.team-member-details ul.social li.link-to-bio-page {
  aspect-ratio: unset;
  flex-basis: auto;
  flex-grow: 1;
  height: auto;
  text-align: right;
  width: auto;
}

dl.team-member-details ul.social li.link-to-bio-page a,
dl.team-member-details ul.social li.link-to-bio-page a::after {
  --arrow-size: 6px;
}

dl.team-member-details ul.social li.link-to-bio-page a {
  --on-surface-color: var(--color-white);
  --surface-color: transparent;
  --size: auto;
  --vertical-offset: calc(var(--arrow-size) / 2);

  box-shadow: none;
  display: inline-flex;
  justify-content: flex-end;

  /* Optical vertical offset */
  margin-top: var(--vertical-offset);
  padding-right: calc(var(--arrow-size) * 2);
  position: relative;
}

dl.team-member-details ul.social li.link-to-bio-page a::after {
  --arrow-color: var(--color-cobalt-blue-200);
  --arrow-reset-size: 0;
  --arrow-position-x: 0;

  border-bottom: var(--arrow-size) solid transparent;
  border-left: var(--arrow-size) solid var(--arrow-color);
  border-top: var(--arrow-size) solid transparent;
  content: "";
  height: var(--arrow-reset-size);
  position: absolute;
  right: 0;
  top: auto;
  transform: translateX(var(--arrow-position-x));
  transition-duration: var(--speed-extra-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-exiting);
  width: var(--arrow-reset-size);
}

dl.team-member-details ul.social li.link-to-bio-page a:hover::after,
dl.team-member-details ul.social li.link-to-bio-page a:active::after,
dl.team-member-details ul.social li.link-to-bio-page a:focus::after {
  --arrow-position-x: calc(var(--arrow-size) / 2);
}

dl.team-member-details ul.social li.link-to-bio-page a:hover::after {
  --arrow-color: var(--color-white);

  transition-timing-function: var(--easing-entering);
}

dl.team-member-details ul.social li.link-to-bio-page a:active::after,
dl.team-member-details ul.social li.link-to-bio-page a:focus::after {
  --arrow-color: var(--color-moss-green-300);

  transition-property: none;
}

/* ----------------------------------------------- */
.team-list {
  --vertical-margin: calc(var(--gutter) / 2);

  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  place-content: stretch flex-start;
}

.team-list li {
  align-self: auto;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
}

.team-list-team-member-container {
  --surface-color: var(--color-midnight-blue-600);
  --on-surface-color: var(--color-white);
  --text-shadow-color: var(--text-shadow-midnight-blue-dark);

  align-items: stretch;
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  place-content: stretch flex-start;
  text-shadow: var(--text-shadow-color);
}

.team-list li:nth-child(3) .team-list-team-member-container,
.team-list li:nth-child(4) .team-list-team-member-container {
  --surface-color: var(--color-midnight-blue-800);
}

.team-list-team-member-cell {
  align-self: auto;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.team-list-team-member-cell.image {
  /* aspect-ratio: 1 / 1; */
  background-color: var(--color-white);
  order: 0;
}

.team-list li:nth-child(3) .team-list-team-member-container .team-list-team-member-cell.image,
.team-list li:nth-child(4) .team-list-team-member-container .team-list-team-member-cell.image {
  order: 1;
}

.team-list-team-member-cell.image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

@media (width <= 768px) {
  .team-list li:not(.team-list ul.social li) {
    border-bottom: 1px solid var(--color-white);
    flex-basis: 100%;
  }

  .team-list li:nth-child(3) .team-list-team-member-container .team-list-team-member-cell.image,
  .team-list li:nth-child(4) .team-list-team-member-container .team-list-team-member-cell.image {
    order: 0;
  }

  dl.team-member-details dd.social-networks {
    --ruler-height: 0;
  }

  .team-list li:nth-child(2) .team-list-team-member-container,
  .team-list li:nth-child(3) .team-list-team-member-container,
  .team-list li:nth-child(4) .team-list-team-member-container,
  .team-list li:nth-child(6) .team-list-team-member-container {
    --surface-color: var(--color-midnight-blue-600);
  }
}

@media (width <= 480px) {
  .team-list-team-member-container {
    flex-wrap: wrap;
  }

  .team-list-team-member-cell.image {
    flex-basis: 25%;
  }
}

@media (width <= 430px) {
  .team-list {
    row-gap: calc(var(--gutter) / 6);
  }

  .team-list li {
    background-color: var(--color-midnight-blue-600);
    border-bottom: none;
  }

  .team-list-team-member-container {
    align-items: flex-start;
    -moz-column-gap: calc(var(--gutter) / 8);
         column-gap: calc(var(--gutter) / 8);
    flex-wrap: nowrap;
  }

  .team-list-team-member-cell.image {
    background-color: var(--color-midnight-blue-600);
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .team-list-team-member-cell {
    flex-basis: auto;
  }

  .team-list-team-member-cell.image img {
    --media-size: 100px;

    background-color: var(--color-white);
    left: unset;
    margin-left: calc(var(--gutter) / 4);
    margin-top: calc(var(--gutter) / 4);
    position: relative;
    top: unset;
  }
}

/* ----------------------------------------------- */
.partners-list,
.partners-list li {
  --gap: calc(var(--gutter) * 2);
}

@media (width <= 768px) {
  .partners-list,
  .partners-list li {
    --gap: var(--gutter);
  }
}

.partners-list {
  --vertical-margin: calc(var(--gutter) / 2);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--gap) var(--gap);
  list-style: none;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  place-content: stretch space-between;
  text-align: center;
}

.partners-list li {
  --cell-width: calc(100% / 3 - var(--gap));

  align-self: auto;
  aspect-ratio: 2 / 1;
  border-color: var(--color-midnight-blue-100);
  border-style: solid;
  border-width: 1px;
  flex-basis: var(--cell-width);
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.partners-list li img {
  --media-size: calc(90% - var(--gap));
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .partners-list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .partners-list li {
    --cell-width: calc(50% - var(--gap));

    flex-grow: 0;
    flex-shrink: 0;
  }
}

@media (width <= 375px) {
  .partners-list {
    row-gap: calc(var(--gap) / 2);
  }

  .partners-list li {
    --cell-width: 100%;
  }

  .partners-list li img {
    --media-size: 140px;
  }
}

/* ----------------------------------------------- */
.key-aspects {
  --vertical-margin: var(--gutter);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  place-content: flex-start flex-start;
  row-gap: 2px;
}

.key-aspects li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.key-aspects dl,
.key-aspects dt,
.key-aspects dd {
  --term-width: 25%;
  --term-minimum-width: 20ch;
}

.key-aspects dl {
  align-items: stretch;

  /* column-gap: var(--gutter); */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 0;
  margin-top: 0;
  place-content: stretch flex-start;
}

.key-aspects dt,
.key-aspects dd {
  --surface-color: var(--color-cobalt-blue-700);
  --on-surface-color: var(--color-white);
  --padding: calc(var(--gutter) / 4);

  align-self: auto;
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: calc(var(--padding) * 2);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  text-shadow: var(--text-shadow-cobalt-blue-dark);
}

.key-aspects dt {
  flex-basis: var(--term-width);
  flex-shrink: 0;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-body-big-fluid);
  min-width: var(--term-minimum-width);
  padding-left: calc(var(--padding) * 2);

  /* white-space: nowrap; */
  width: var(--term-width);
}

.key-aspects dd {
  --surface-color: var(--color-cobalt-blue-050);
  --on-surface-color: var(--color-cobalt-blue-1000);

  font-family: var(--font-family-quicksand-medium);
  line-height: var(--type-scale-golden-ratio);
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 4);
  padding-top: calc(var(--padding) + 0.05em);
  text-shadow: var(--text-shadow-white);
}

.key-aspects dt.alternate {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-900);

  text-shadow: var(--text-shadow-white);
}

.key-aspects dd.alternate {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-1000);

  padding-left: 0;

  /* padding-left: var(--padding); */
  padding-right: var(--padding);
  padding-top: var(--padding);
}

@media (width <= 540px) {
  .key-aspects dl,
  .key-aspects dt,
  .key-aspects dd {
    --term-width: 100%;
    --term-minimum-width: 20ch;
  }

  .key-aspects dl {
    flex-wrap: wrap;
  }

  .key-aspects dt {
    padding-bottom: var(--padding);
  }

  .key-aspects dd.alternate {
    padding-right: 0;
    padding-top: 0;
  }

  .key-aspects dd.alternate .link-with-image {
    margin-left: calc(var(--gutter) / 2);
    margin-right: calc(var(--gutter) / 2);
  }
}

/* ----------------------------------------------- */
.advisers-list {
  --gap: calc(var(--gutter) / 2);

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  list-style: none;
  margin-bottom: var(--gap);
  margin-top: var(--gap);
  padding: 0;
  place-content: flex-start flex-start;
}

/* @media (width <= 540px) {
  .advisers-list {
    justify-content: space-between;
  }
} */

.advisers-list li {
  --item-width: calc(25% - var(--gap));

  align-self: auto;
  flex-basis: var(--item-width);
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 128px;
}

@media (width <= 768px) {
  .advisers-list li {
    --item-width: calc(100% / 3 - var(--gap));

    max-width: unset;
  }
}

@media (width <= 540px) {
  .advisers-list li {
    --item-width: calc(50% - var(--gap));
  }
}

.advisers-list dl {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
  place-content: flex-start flex-start;
  position: relative;
  row-gap: calc(var(--gutter) / 4);
}

.advisers-list dt {
  display: none;
}

.advisers-list dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  order: 1;
  padding: 0;
}

.advisers-list dd.image {
  aspect-ratio: 1 / 1;
  background-color: var(--color-white);
  order: 0;
  position: relative;
  width: 100%;
}

.advisers-list dd.image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

.advisers-list dd.image svg {
  fill: var(--color-midnight-blue-900);
}

.advisers-list dd.name,
.advisers-list dd.name span {
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-perfect-fourth);
}

.advisers-list dd.name span {
  display: block;
}

.advisers-list dd.name a {
  color: var(--color-black);
}

.advisers-list dd.image a {
  --size: 100%;

  border-color: var(--color-black);
  border-style: solid;
  border-width: 1px;
  display: block;
  height: var(--size);
  position: relative;
  width: var(--size);
}

.advisers-list dd.image a:hover {
  border-color: var(--color-cobalt-blue-600);
}

.advisers-list dd.image a:active,
.advisers-list dd.image a:focus {
  border-color: var(--color-cobalt-blue-900);
}

.advisers-list dd.linkedin-url {
  --icon-size: 20px;
  --position-xy: 0;

  bottom: var(--position-xy);
  flex-basis: var(--icon-size);
  height: var(--icon-size);
  position: absolute;
  right: var(--position-xy);
  transform: translateY(calc(var(--icon-size) * 0.75 * -1));
  width: var(--icon-size);
}

.advisers-list dd.linkedin-url a {
  --surface-color: var(--color-cobalt-blue-100);
  --on-surface-color: var(--color-cobalt-blue-700);
  --size: 100%;

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--size);
  place-content: center center;
  position: relative;
  text-decoration: none;
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: var(--size);
}

dd.linkedin-url svg {
  --freespace-margin: 8px;
  --size: calc(100% - var(--freespace-margin));
  --position-xy: calc(50% - (var(--size) / 2));

  aspect-ratio: 1 / 1;
  fill: currentcolor;
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
}

/* ----------------------------------------------- */
.advisers-list dd.linkedin-url a:hover {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-900);

  transition-timing-function: var(--easing-entering), var(--easing-entering);
}

.advisers-list dd.linkedin-url a:active,
.advisers-list dd.linkedin-url a:focus {
  --surface-color: var(--color-moss-green-300);
  --on-surface-color: var(--color-moss-green-1000);

  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
  transition-property: none;
}

/* ----------------------------------------------- */
.rss-feed,
.feed-item,
.feed-item-cell,
.headline-with-date,
.feed-item [itemprop="description"],
.feed-item .source {
  --gap: calc(var(--gutter) / 2);
}

.rss-feed {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
  margin-top: calc(var(--gap) * 2);
  padding: 0;
  place-content: flex-start flex-start;
  row-gap: var(--gap);
}

.rss-feed li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
}

/* ----------------------------------------------- */
.feed-item,
.feed-item-cell {
  --default-image-width: 128px;
}

.feed-item {
  align-items: flex-start;
  border-bottom: 1px solid var(--color-midnight-blue-200);
  -moz-column-gap: calc(var(--gap) * 2);
       column-gap: calc(var(--gap) * 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: var(--gap);
  padding-top: var(--gap);
  place-content: flex-start flex-start;
}

.rss-feed li:last-child .feed-item {
  border-bottom: none;
}

.feed-item-cell {
  align-self: auto;

  /* flex-basis: calc(100% - var(--default-image-width) - var(--gap)); */
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.feed-item-cell-image {
  aspect-ratio: 16 / 9;
  background-color: var(--color-midnight-blue-050);
  flex-basis: var(--default-image-width);
  flex-grow: 0;
  flex-shrink: 0;
  order: 0;
  position: relative;
}

.feed-item-cell-image a {
  /* box-shadow: var(--shadow-elevation-low); */
  display: block;
  height: 100%;

  /* transition-duration: var(--speed-extra-fast);
  transition-property: box-shadow;
  transition-timing-function: var(--easing-exiting); */
}

/* .feed-item-cell-image a:hover {
  box-shadow: var(--shadow-elevation-medium);
  transition-duration: var(--speed-fast);
} */

.feed-item-cell-image[data-is-valid-image="false"] {
  background-color: transparent;
}

.feed-item-cell-image[data-is-valid-image="false"] a {
  display: none;
}

.feed-item-cell-image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  overflow: hidden;
  position: absolute;
  scale: 1;
  top: var(--media-position);
  transform-origin: center;
  width: var(--media-size);

  /* z-index: 0; */
}

.feed-item-cell-image a:hover img {
  transform: scale(1.05);
  transition-duration: var(--speed-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-entering);
}

/* ----------------------------------------------- */
.feed-item .headline-with-date,
.feed-item .headline-with-date dd,
.feed-item [itemprop="description"],
.feed-item .source {
  --date-stamp-width: 12ch;
}

.feed-item .headline-with-date {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--gap) / 2) calc(var(--gap) * 2);
  margin-bottom: calc(var(--gap) * 0.5);
  margin-top: calc(var(--gap) / 2 * -1);
  place-content: flex-start flex-start;
}

.feed-item .headline-with-date dt {
  display: none;
}

.feed-item .headline-with-date dd,
.feed-item .headline-with-date dd span,
.feed-item .source {
  line-height: var(--type-scale-perfect-fourth);
}

.feed-item .headline-with-date dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.feed-item .headline-with-date dd.headline {
  flex-basis: calc(100% - var(--date-stamp-width) - var(--gap) * 2);
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-body-fluid);
}

.feed-item .headline-with-date dd.headline a,
.feed-item .source a {
  color: var(--color-cobalt-blue-900);
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.feed-item .headline-with-date dd.headline a:hover,
.feed-item .source a:hover {
  color: var(--color-cobalt-blue-700);
  transition-duration: var(--speed-extra-fast);
}

.feed-item .headline-with-date dd.headline a:active,
.feed-item .headline-with-date dd.headline a:focus,
.feed-item .source a:active,
.feed-item .source a:focus {
  color: var(--color-orange-600);
}

.feed-item .headline-with-date dd.date {
  --surface-color: var(--color-midnight-blue-050);
  --on-surface-color: var(--color-midnight-blue-900);
  --font-size: var(--font-size-body-small);
  --padding: calc(var(--font-size) / 4);

  background-color: var(--surface-color);
  color: var(--on-surface-color);
  flex-basis: var(--date-stamp-width);
  flex-grow: 0;
  flex-shrink: 0;
  font-size: var(--font-size);
  line-height: var(--font-size);

  /* Optical offset */
  margin-top: -1px;
  padding-bottom: calc(var(--padding) + 1px);
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 2);
  padding-top: var(--padding);
  text-align: center;
  text-shadow: var(--text-shadow-white);
  white-space: nowrap;
  width: var(--date-stamp-width);
}

/* ----------------------------------------------- */
.feed-item [itemprop="description"] {
  font-size: var(--font-size-body-small);
  margin-bottom: 0;
  margin-right: calc(var(--date-stamp-width) - var(--gap));
  margin-top: 0;
  padding-bottom: calc(var(--gap) / 2);
  padding-right: calc(var(--gap) * 4);
}

/* ----------------------------------------------- */
.feed-item .headline-with-date time,
.feed-item .headline-with-date [itemprop="headline"] {
  --date-stamp-width: 20%;
}

.feed-item .headline-with-date time {
  flex-basis: var(--date-stamp-width);
  flex-grow: 0;
  flex-shrink: 0;
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-small-fluid);
  white-space: nowrap;
}

.feed-item .headline-with-date [itemprop="headline"] {
  flex-basis: calc(100% - var(--date-stamp-width) - var(--gap));
  flex-grow: 1;
  flex-shrink: 1;
}

/* ----------------------------------------------- */
.feed-item .source {
  border-top: 1px solid var(--color-midnight-blue-050);
  color: var(--color-cobalt-blue-900);
  display: block;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-body-small-fluid);
  margin-right: calc(var(--date-stamp-width) + var(--gap) * 2);
  padding-bottom: calc(var(--gap) / 2);
  padding-top: calc(var(--gap) / 2);
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .feed-item [itemprop="description"],
  .feed-item .source {
    margin-right: 0;
    padding-right: 0;
  }

  .feed-item .headline-with-date {
    margin-bottom: calc(var(--gap) * 1);
  }
}

@media (width <= 540px) {
  .feed-item,
  .feed-item-cell {
    --default-image-width: 100px;
  }

  .feed-item .headline-with-date {
    flex-wrap: wrap;
  }

  .feed-item .headline-with-date dd.headline {
    flex-basis: 100%;
  }
}

@media (width <= 480px) {
  .feed-item [itemprop="description"] {
    display: none;
  }

  .feed-item {
    -moz-column-gap: var(--gap);
         column-gap: var(--gap);
    padding-bottom: calc(var(--gap) / 2);
    padding-top: calc(var(--gap) / 2);
  }

  .feed-item .headline-with-date {
    margin-top: calc(var(--gap) * 0.25 * -1);
  }
}

@media (width <= 375px) {
  .feed-item-cell-image {
    display: none;
  }
}

/* ----------------------------------------------- */
ol[itemtype="https://schema.org/BreadcrumbList"],
ol[itemtype="https://schema.org/BreadcrumbList"] a
{
  --on-surface-color: var(--color-midnight-blue-950);

  color: var(--on-surface-color);
  text-shadow: var(--text-shadow-white);
}

ol[itemtype="https://schema.org/BreadcrumbList"],
ol[itemtype="https://schema.org/BreadcrumbList"] span,
ol[itemtype="https://schema.org/BreadcrumbList"] li
{
  --font-size: var(--font-size-body-extra-small);
  --gap: calc(var(--font-size) * 1.5);

  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size);
  line-height: var(--font-size);
}

ol[itemtype="https://schema.org/BreadcrumbList"]
{
  --padding: calc(var(--gap) / 3);
  --surface-color: var(--color-midnight-blue-050);
  --vertical-offset: calc(var(--gap) * 2.25 * -1);

  align-items: center;
  background-color: var(--surface-color);

  /* border-bottom: 2px solid var(--color-midnight-blue-200); */
  -moz-column-gap: var(--gap);
       column-gap: var(--gap);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: auto;
  list-style: none;
  margin-bottom: var(--gap);
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  place-content: flex-start flex-start;
  position: absolute;
  top: auto;
  transform: translateY(var(--vertical-offset));
}

@media (width <= 768px) {
  ol[itemtype="https://schema.org/BreadcrumbList"]
  {
    --vertical-offset: 0;

    position: relative;
  }
}

ol[itemtype="https://schema.org/BreadcrumbList"] li
{
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

ol[itemtype="https://schema.org/BreadcrumbList"] li:first-child
{
  display: none;
}

ol[itemtype="https://schema.org/BreadcrumbList"] li:not(:last-child):after
{
  content: "/";
  position: absolute;
  right: calc(var(--gap) * 0.7 * -1);
}

ol[itemtype="https://schema.org/BreadcrumbList"] [aria-current="page"] a
{
  pointer-events: none;
}

/* ----------------------------------------------- */
.next-and-previous-page-in-section-navigation,
.next-and-previous-page-in-section-navigation a,
.next-and-previous-page-in-section-navigation a::before,
.next-and-previous-page-in-section-navigation a::after {
  --on-surface-color: var(--color-white);

  color: var(--on-surface-color);
}

.next-and-previous-page-in-section-navigation {
  --surface-color: var(--color-midnight-blue-700);
  --font-size: var(--font-size-body-small);

  align-items: center;
  background-color: var(--surface-color);
  -moz-column-gap: calc(var(--gutter) * 2);
       column-gap: calc(var(--gutter) * 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
  place-content: center space-between;
}

.next-and-previous-page-in-section-navigation li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.next-and-previous-page-in-section-navigation a,
.next-and-previous-page-in-section-navigation a::after,
.next-and-previous-page-in-section-navigation a::before {
  --arrow-width: 76px;
}

.next-and-previous-page-in-section-navigation a {
  --horizontal-margin: calc(var(--gutter) / 4);
  --horizontal-padding: calc(var(--arrow-width) / 3);
  --padding-vertical: calc(var(--gutter) / 3);

  display: inline-block;
  padding-bottom: var(--padding-vertical);
  padding-top: var(--padding-vertical);
  position: relative;
  text-shadow: var(--text-shadow-midnight-blue-dark);
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.next-and-previous-page-in-section-navigation a:hover {
  --on-surface-color: var(--color-cobalt-blue-400);

  transition-duration: var(--speed-extra-fast);
}

.next-and-previous-page-in-section-navigation a:hover::after,
.next-and-previous-page-in-section-navigation a:hover::before {
  --on-surface-color: var(--color-cobalt-blue-100);
}

.next-and-previous-page-in-section-navigation a:active,
.next-and-previous-page-in-section-navigation a:focus {
  --on-surface-color: var(--color-white);

  text-shadow: none;
  transition-property: none;
}

.next-and-previous-page-in-section-navigation a::after,
.next-and-previous-page-in-section-navigation a::before {
  --arrow-height: 73.5px;
  --arrow-x-offset: 0.4;
  --position-y: calc(50% - var(--arrow-height) / 2);

  background-color: var(--on-surface-color);
  display: block;
  height: var(--arrow-height);
  position: absolute;
  top: var(--position-y);
  transform: scale(0.2);
  width: var(--arrow-width);
}

.next-and-previous-page-in-section-navigation a[rel="prev"] {
  margin-left: var(--horizontal-margin);
  padding-left: var(--horizontal-padding);
}

.next-and-previous-page-in-section-navigation a[rel="next"] {
  margin-right: var(--horizontal-margin);
  padding-right: var(--horizontal-padding);
}

.next-and-previous-page-in-section-navigation a[rel="prev"]::before {
  clip-path: polygon(0 50%, 48.4% 0, 48.4% 31%, 100% 31%, 100% 69%, 48.4% 69%, 48.4% 100%);
  content: "";
  left: calc(var(--arrow-width) * var(--arrow-x-offset) * -1);
}

.next-and-previous-page-in-section-navigation a[rel="next"]::after {
  clip-path: polygon(0 31%, 51.6% 31%, 51.6% 0, 100% 50%, 51.6% 100%, 51.6% 69%, 0 69%);
  content: "";
  right: calc(var(--arrow-width) * var(--arrow-x-offset) * -1);
}

@media (width <= 480px) {
  .next-and-previous-page-in-section-navigation li.previous {
    display: none;
  }

  .next-and-previous-page-in-section-navigation {
    justify-content: flex-end;
  }
}

/* ----------------------------------------------- */
.team-picture-quilt {
  --gap: 0;

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  height: 100%;
  list-style: none;
  margin-bottom: calc(var(--gutter) / 2);
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
  place-content: flex-start flex-start;
}

.team-picture-quilt li {
  --picture-width: 25%;

  align-self: auto;
  aspect-ratio: 4 / 9;
  flex-basis: var(--picture-width);
  flex-grow: 1;
  flex-shrink: 1;
  height: 50%;
  position: relative;
  width: var(--picture-width);
}

@media (width <= 768px) {
  .team-picture-quilt li {
    --picture-width: calc(100% / 3);
  }
}

@charset 'UTF-8';

ul.social {
  align-items: center;
  -moz-column-gap: calc(var(--gutter) / 4);
       column-gap: calc(var(--gutter) / 4);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-start;
}

ul.social.share-page {
  display: flex;
}

.social.share-buttons::before,
.social.follow-buttons::before {
  --font-size: var(--font-size-body-extra-small);
  --on-surface-color: var(--color-black);

  color: var(--on-surface-color);
  content: "Share";
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size);
  line-height: var(--font-size);
}

.social.follow-buttons::before {
  --on-surface-color: var(--color-white);

  content: "Follow";

  /* Optical offset to accommodate box shadow applied to the icons */
  margin-top: 2px;
}

.footer-links li,
ul.social li {
  --social-icon-size: 24px;
}

ul.social li {
  align-self: auto;
  aspect-ratio: 1 / 1;
  flex-basis: var(--social-icon-size);
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--social-icon-size);
  position: relative;
  width: var(--social-icon-size);
}

ul.social.share-page li {
  --social-icon-size: 22px;
}

ul.social a,
ul.social button {
  --size: 100%;
  --surface-color: var(--color-cobalt-blue-800);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--size);
  place-content: center center;
  text-decoration: none;
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: var(--size);
}

[data-color-scheme="neon-rose"] ul.social a,
[data-color-scheme="neon-rose"] ul.social button {
  --surface-color: var(--color-neon-rose-500);
}

.bottom-app-bar ul.social a,
.bottom-app-bar ul.social button {
  box-shadow: var(--shadow-elevation-low);
}

ul.social svg {
  --freespace-margin: 10px;
  --size: calc(100% - var(--freespace-margin));
  --position-xy: calc(50% - (var(--size) / 2));

  aspect-ratio: 1 / 1;
  fill: currentcolor;
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
}

ul.social .tiktok svg {
  --freespace-margin: 6px;
}

ul.social .facebook svg {
  --freespace-margin: 8px;
}

/* ----------------------------------------------- */
ul.social a:hover,
ul.social button:hover {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-1000);

  transition-timing-function: var(--easing-entering), var(--easing-entering);
}

ul.social a:active,
ul.social a:focus,
ul.social button:active,
ul.social button:focus {
  --surface-color: var(--color-moss-green-300);
  --on-surface-color: var(--color-moss-green-1000);

  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
  transition-property: none;
}

.bottom-app-bar ul.social a:hover,
.bottom-app-bar ul.social button:hover {
  box-shadow: var(--shadow-elevation-medium);
}

[data-color-scheme="neon-rose"] ul.social a:hover,
[data-color-scheme="neon-rose"] ul.social button:hover {
  --surface-color: var(--color-neon-rose-200);
  --on-surface-color: var(--color-neon-rose-900);
}

/* stylelint-disable declaration-no-important */
/* stylelint-disable no-descending-specificity */
@charset 'UTF-8';

main.fullscreen {
  min-height: 100vh;
}

/* ----------------------------------------------- */
.on-page-hero-image,
.on-page-hero-image::after {
  --image-max-height: 384px;
  --image-min-height: 320px;
  --image-preferred-height: calc(100vh / 3);
}

.on-page-hero-image {
  --surface-color: var(--color-cobalt-blue-050);

  align-items: center;
  aspect-ratio: 16 / 9;
  background-color: var(--surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: var(--gutter);
  overflow: hidden;
  place-content: center center;
  position: relative;
  width: 100%;
}

.on-page-hero-image[data-variant="shallow"] {
  height: clamp(var(--image-min-height), var(--image-preferred-height), var(--image-max-height));
}

.on-page-hero-image-with-caption {
  align-items: flex-start;
  justify-content: flex-start;
}

.on-page-hero-image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  opacity: 0;
  position: absolute;
  top: var(--media-position);
  transition-duration: var(--speed-fast);
  transition-property: opacity;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--media-size);
  z-index: 0;
}

.on-page-hero-image.join img {
  -o-object-position: top;
     object-position: top;
}

[data-is-loaded="true"] .on-page-hero-image img {
  opacity: 1;
}

.on-page-hero-image::after {
  --position-xy: 0;

  background-color: var(--color-white);
  clip-path: polygon(var(--hero-angle) 0%, 100% 0%, calc(100% - var(--hero-angle)) 100%, 0% 100%);
  content: "";
  height: 100%;
  left: var(--position-xy);
  opacity: 0.9;
  position: absolute;
  top: var(--position-xy);
  transform: translateX(-100%);
  transition-duration: var(--speed-slow), var(--speed-normal);
  transition-property: opacity, transform;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-exiting);
  width: clamp(var(--image-min-height), var(--image-preferred-height), var(--image-max-height));
  z-index: 1;
}

[data-is-loaded="true"] .on-page-hero-image::after {
  opacity: 0;
  transform: translateX(280%);
}

/* ----------------------------------------------- */
.on-page-hero-image-with-caption .caption {
  --on-surface-color: var(--color-white);
  --surface-color: var(--color-cobalt-blue-700);
  --position-xy: calc(var(--gutter) / 2);
  --caption-min-width: 250px;
  --caption-preferred-width: 25%;
  --caption-max-width: 375px;

  background-color: var(--surface-color);
  bottom: var(--position-xy);
  box-shadow: var(--shadow-elevation-medium);
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size-body-big-fluid);
  height: auto;
  left: var(--position-xy);
  line-height: var(--type-scale-perfect-fourth);
  padding-bottom: calc(var(--gutter) / 2);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: calc(var(--gutter) / 2);
  position: absolute;
  text-shadow: var(--text-shadow-cobalt-blue-dark);
  width: clamp(var(--caption-min-width), var(--caption-preferred-width), var(--caption-max-width));
  z-index: 2;
}

.on-page-hero-image-with-caption .caption[data-align="bottom-right"] {
  left: auto;
  right: var(--position-xy);
}

.on-page-hero-image-with-caption .caption cite {
  --font-size: var(--font-size-body-small-fluid);

  display: block;
  font-size: var(--font-size);
  margin-top: calc(var(--font-size) / 2);
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .on-page-hero-image-with-caption .caption {
    padding-left: calc(var(--gutter) / 2);
    padding-right: calc(var(--gutter) / 2);
  }
}

@media (width <= 375px) {
  .on-page-hero-image[data-variant="shallow"] {
    height: unset;
  }

  .on-page-hero-image {
    flex-wrap: wrap;
    overflow: visible;
  }

  .on-page-hero-image img {
    height: auto;
    left: auto;
    position: relative;
    top: auto;
    width: 100%;
  }

  .on-page-hero-image-with-caption .caption {
    bottom: auto;
    box-shadow: none;
    flex-basis: 100%;
    left: unset !important;
    position: relative;
    right: unset !important;
    width: 100%;
  }
}

/* ----------------------------------------------- */
.on-page-hero-image.stop-child-traffic {
  --image-min-height: 128px;
  --image-max-height: 192px;

  background-color: var(--color-white);
  color: var(--stop-child-traffic-red);
  justify-content: flex-start;
}

.on-page-hero-image.stop-child-traffic svg {
  fill: currentcolor;
}

/* ----------------------------------------------- */
[data-page-name="foundation"] .on-page-hero-image-with-caption .caption {
  --surface-color: var(--color-neon-rose-500);

  text-shadow: var(--text-shadow-neon-rose-dark);
}

@charset 'UTF-8';

.skeleton-loader {
  /* Add styles for the loader container */
}

.skeleton-item {
  margin-bottom: 20px;
}

.skeleton-title,
.skeleton-date,
.skeleton-description {
  animation: pulse 1.5s infinite;
  background-color: #f0f0f0;
  height: 20px;
  margin-bottom: 10px;
}

.skeleton-title {
  width: 70%;
}

.skeleton-date {
  width: 30%;
}

.skeleton-description {
  height: 60px;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

@charset 'UTF-8';

.form-instructions {
  font-family: var(--font-family-quicksand-medium);
}

label,
input,
select,
button[type="submit"],
textarea {
  --font-size: var(--font-size-body-small);

  -webkit-appearance: none;

     -moz-appearance: none;

          appearance: none;
  font-size: var(--font-size);
  line-height: var(--type-scale-perfect-fourth);
}

textarea {
  --default-height: calc(var(--font-size) * 6);

  height: var(--default-height);
  resize: vertical;
}

/* ----------------------------------------------- */
label,
label em {
  color: var(--color-cobalt-blue-700);
  display: block;
  font-family: var(--font-family-quicksand-bold);
}

label {
  position: relative;
}

label em {
  font-style: normal;
  margin-bottom: calc(var(--font-size) * 0.5);
}

.label-for-dropdown::after {
  --arrow-size: 7px;
  --reset-size: 0;
  --reset-color: transparent;
  --arrow-color: var(--color-black);
  --arrow-style: solid;
  --position-y: calc(50% - var(--arrow-size) / 2);
  --position-x: calc(var(--arrow-size) * 1.25);
  --vertical-offset: calc(var(--font-size) * 0.85);

  border-left: var(--arrow-size) var(--arrow-style) var(--reset-color);
  border-right: var(--arrow-size) var(--arrow-style) var(--reset-color);
  border-top: var(--arrow-size) var(--arrow-style) var(--arrow-color);
  content: "";
  display: block;
  height: var(--reset-size);
  pointer-events: none;
  position: absolute;
  right: var(--position-x);
  top: var(--position-y);
  transform: translateY(var(--vertical-offset));
  width: var(--reset-size);
}

.label-for-checkbox {
  align-items: center;
  -moz-column-gap: calc(var(--gutter) / 4);
       column-gap: calc(var(--gutter) / 4);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  place-content: flex-start flex-start;
}

/* ----------------------------------------------- */
input,
select,
textarea {
  --border-width: 1px;
  --surface-color: var(--color-white);
  --on-surface-color: var(--color-black);
  --padding: calc(var(--font-size) * 0.75);

  background-color: var(--surface-color);
  border-color: var(--on-surface-color);
  border-radius: 0;
  border-style: solid;
  border-width: var(--border-width);
  color: var(--on-surface-color);
  display: inline-flex;
  font-family: var(--font-family-quicksand-medium);
  outline-color: transparent;
  outline-style: solid;
  outline-width: calc(var(--border-width) * 4);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  transition-duration: var(--speed-fast);
  transition-property: outline-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: 100%;
}

input[type="checkbox"] {
  --size: 24px;
  --inner-size: calc(var(--size) * 0.6);
  --inner-color: transparent;

  background-color: transparent;
  background-image: linear-gradient(var(--inner-color), var(--inner-color));
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: var(--inner-size) var(--inner-size);
  height: var(--size);
  width: var(--size);
}

input:hover,
select:hover,
textarea:hover {
  border-color: var(--color-cobalt-blue-600);
}

input:focus,
select:focus,
textarea:focus {
  border-color: var(--color-cobalt-blue-900);
  outline-color: var(--color-cobalt-blue-100);
  transition-duration: var(--speed-extra-fast);
}

input[type="checkbox"]:checked {
  --inner-color: var(--color-cobalt-blue-800);

  background-color: var(--color-cobalt-blue-050);
}

button[type="submit"] {
  --font-size: var(--font-size-body);
  --padding: calc(var(--font-size) * 0.75);
  --surface-color: var(--color-cobalt-blue-600);
  --on-surface-color: var(--color-white);

  background-color: var(--surface-color);
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  display: inline-flex;
  font-family: var(--font-family-havelock-black);
  font-size: var(--font-size);
  line-height: var(--font-size);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  text-shadow: var(--text-shadow-cobalt-blue);
  transition-duration: var(--speed-extra-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity),
    var(--easing-color-or-opacity);
}

button[type="submit"]:hover {
  --surface-color: var(--color-cobalt-blue-050);
  --on-surface-color: var(--color-cobalt-blue-900);

  border-color: var(--color-cobalt-blue-900);
  box-shadow: var(--shadow-elevation-medium);
  text-shadow: var(--text-shadow-white);
}

button[type="submit"]:active,
button[type="submit"]:focus {
  --surface-color: var(--color-moss-green-700);
  --on-surface-color: var(--color-white);

  border-color: transparent;
  box-shadow: none;
  text-shadow: none;
  transition-property: none;
}

/* ----------------------------------------------- */
form [role="alert"] {
  color: var(--color-error);
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: calc(var(--gutter) / 4);
}

/* ----------------------------------------------- */
.contact-form,
.contact-form-cell {
  --gap: var(--gutter);
  --cell-width: 50%;
}

.contact-form {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  margin-bottom: var(--gutter);
  margin-top: var(--gutter);
  place-content: flex-start flex-start;
}

.contact-form-cell {
  align-self: auto;
  flex-basis: calc(var(--cell-width) - var(--gap));
  flex-grow: 1;
  flex-shrink: 1;
}

.contact-form-cell-message,
.contact-form-cell-subscribe {
  flex-basis: 100%;
}

@charset 'UTF-8';

.dialog,
.dialog-cell,
.dialog .header {
  --gap: calc(var(--gutter) / 2);
  --hero-image-width: calc(100% / 2.5);
}

.dialog {
  --width: 360px;
  --position-x: calc(50% - var(--width) / 2);
  --position-y: 25vh;
  --surface-color: var(--color-midnight-blue-100);
  --on-surface-color: var(--color-midnight-blue-1000);

  align-items: stretch;
  background-color: var(--surface-color);
  box-shadow: var(--shadow-elevation-high);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size-body-small);
  gap: 0 var(--gap);
  left: var(--position-x);
  line-height: var(--type-scale-perfect-fourth);
  opacity: 0;
  place-content: flex-start flex-start;
  pointer-events: none;
  position: fixed;
  text-shadow: var(--text-shadow-white);
  top: var(--position-y);
  width: var(--width);
  z-index: 1000;
}

.dialog[data-is-visible="false"] {
  transition-duration: var(--speed-extra-fast), var(--speed-fast);
  transition-property: opacity, transform;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-exiting);
}

.dialog[data-is-visible="true"] {
  opacity: 1;
  pointer-events: auto;
  transition-duration: var(--speed-fast), var(--speed-fast);
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-entering);
}

.dialog[data-anchor-position="bottom"] {
  --position-y: 20vh;

  bottom: var(--position-y);
  top: auto;
  transform: translateY(100%);
}

.dialog[data-anchor-position="bottom"][data-is-visible="true"] {
  transform: translateY(0);
}

/* ----------------------------------------------- */
.dialog .header,
.dialog-cell-content,
.close-dialog {
  --close-dialog-button-size: 22px;
  --padding-horizontal: calc(var(--gutter) / 3);
}

.dialog-cell,
.dialog-cell-image a {
  position: relative;
}

.dialog-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.dialog-cell-image {
  flex-basis: var(--hero-image-width);
  flex-grow: 0;
  flex-shrink: 0;
  order: 0;
  overflow: hidden;
  width: var(--hero-image-width);
}

.dialog .header {
  --underline-color: var(--color-white);

  border-bottom: 1px solid var(--underline-color);
  color: unset;
  font-size: var(--font-size-body-big-fluid);
  margin-bottom: 0;
  margin-top: var(--gap);
  padding-bottom: calc(var(--gap) / 2);
  padding-right: calc(var(--close-dialog-button-size) * 1.5);
}

.dialog p {
  font-size: unset;
}

.dialog-cell-content {
  flex-basis: calc(100% - var(--hero-image-width) - var(--gap));
  padding-bottom: calc(var(--gap) * 2);
  padding-right: var(--gap);
}

/* ----------------------------------------------- */
.dialog-cell-image a {
  display: block;
  height: 100%;
}

.dialog-cell-image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
}

/* ----------------------------------------------- */
img.dialog-image-landscape {
  display: none;
}

/* ----------------------------------------------- */
.close-dialog::after,
.close-dialog::before {
  --on-surface-color: var(--color-white);
  --height: 2px;
  --width: calc(100% - var(--height) * 4);
  --position-x: calc(50% - var(--width) / 2);
  --position-y: calc(50% - var(--height) / 2);

  background-color: var(--on-surface-color);
  content: "";
  height: var(--height);
  left: var(--position-x);
  position: absolute;
  top: var(--position-y);
  transform: rotate(45deg);
  transform-origin: center;
  width: var(--width);
}

.close-dialog::before {
  transform: rotate(-45deg);
}

.close-dialog {
  --position-xy: calc(var(--padding-horizontal) / 2);
  --surface-color: var(--color-black);

  background-color: var(--surface-color);
  color: transparent;
  height: var(--close-dialog-button-size);
  overflow: hidden;
  position: absolute;
  right: var(--position-xy);
  top: var(--position-xy);
  transition-duration: var(--speed-extra-fast), var(--speed-fast);
  transition-property: background-color, scale;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-snap);
  width: var(--close-dialog-button-size);
}

/* .donate .close-dialog {
  --surface-color: var(--color-neon-rose-950);
} */

.close-dialog:hover {
  --surface-color: var(--color-black);

  transition-duration: var(--speed-super-fast), var(--speed-extra-fast);
}

.close-dialog:hover::after,
.close-dialog:hover::before {
  scale: 0.8;
}

/* ----------------------------------------------- */
.dialog.donate {
  --surface-color: var(--color-neon-rose-500);
  --on-surface-color: var(--color-white);

  text-shadow: var(--text-shadow-neon-rose-dark);
}

.dialog.donate .header {
  --underline-color: var(--color-neon-rose-050);
}

/* ----------------------------------------------- */
.dialog .button-link {
  --button-radius: var(--rounded-corners-small);
  --font-size: var(--font-size-body-small);
  --surface-color: var(--color-neon-rose-050);
  --on-surface-color: var(--color-neon-rose-500);

  background-color: var(--surface-color);
  border-radius: var(--button-radius);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  display: inline-block;
  font-family: var(--font-family-havelock-black);
  font-size: var(--font-size);
  line-height: var(--font-size);
  padding: calc(var(--button-radius) * 2);
  text-shadow: var(--text-shadow-white);
  transition-duration: var(--speed-fast), var(--speed-fast), var(--speed-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity),
    var(--easing-color-or-opacity);
}

.dialog .button-link:hover,
.dialog .button-link:active,
.dialog .button-link:focus {
  --surface-color: var(--color-white);
  --on-surface-color: var(--color-neon-rose-600);
}

.dialog .button-link:hover {
  box-shadow: var(--shadow-elevation-medium);
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast), var(--speed-extra-fast);
}

.dialog .button-link:active,
.dialog .button-link:focus {
  --on-surface-color: var(--color-neon-rose-500);

  box-shadow: none;
  text-shadow: none;
  transition-property: none;
}

/* ----------------------------------------------- */
@media (width <= 400px) {
  .dialog,
  .dialog-cell,
  .dialog .header {
    --hero-image-width: 100%;
  }

  .dialog {
    --width: 275px;

    flex-direction: column;
  }

  .dialog-cell-image {
    aspect-ratio: 16 / 9;
    order: 1;
  }

  img.dialog-image-portrait {
    display: none;
  }

  img.dialog-image-landscape {
    display: block;
  }

  .dialog-cell-content {
    padding-bottom: var(--gap);
    padding-left: var(--gap);
    padding-right: var(--gap);
  }
}

@charset 'UTF-8';

.alert,
.alert div,
.alert-icon {
  --icon-size: 20px;
}

.alert,
.alert div {
  --gap: calc(var(--gutter) / 4);
}

.alert {
  --surface-color: var(--color-white);
  --on-surface-color: var(--color-black);
  --font-size: var(--font-size-body-small);
  --position-x: var(--gutter);
  --position-y: var(--gutter);
  --min-width: 200px;
  --max-width: 414px;
  --preferred-width: 100%;

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-medium);
  color: var(--on-surface-color);
  -moz-column-gap: var(--gap);
       column-gap: var(--gap);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  place-content: flex-start flex-start;
  pointer-events: none;
  position: fixed;
  right: var(--position-x);
  top: var(--position-y);
  transform: translateX(calc(100% + var(--gutter)));
  visibility: hidden;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
  z-index: 1000;
}

.alert[data-alert-is-visible="true"] {
  pointer-events: all;
  transform: translateX(0);
  transition-duration: var(--speed-extra-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-entering);
  visibility: visible;
}

.alert div {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;

  /* order: 0; */
  position: relative;
}

.alert div.icon,
.alert div.action {
  --padding: calc(var(--icon-size) / 2);

  flex-basis: var(--icon-size);
  flex-grow: 0;
  padding: var(--padding);
}

.alert div.action {
  --padding: calc(var(--icon-size) / 4);

  align-self: flex-start;
}

.alert div.message {
  padding-bottom: var(--gap);
  padding-top: var(--gap);
}

/* ----------------------------------------------- */
.alert-icon {
  --surface-color: var(--color-midnight-blue-900);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-radius: 100%;
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--icon-size);
  place-content: center center;
  width: var(--icon-size);
}

.alert-icon svg {
  fill: currentcolor;
  stroke-width: 0;
}

/* ----------------------------------------------- */
.alert-dismiss {
  /* stylelint-disable-next-line declaration-no-important */
  --surface-color: transparent !important;
  --on-surface-color: var(--color-midnight-blue-900);
}

/* ----------------------------------------------- */
.alert.success {
  --surface-color: var(--color-moss-green-600);
  --on-surface-color: var(--color-white);

  text-shadow: var(--text-shadow-moss-green);
}

.alert.success .alert-icon {
  --surface-color: var(--color-moss-green-950);
}

.alert.success .alert-dismiss {
  --on-surface-color: var(--color-moss-green-1000);
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/3fd1467a3a24a9e6-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/e5a448ebd52a1afd-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/1218ffa1d5ea28b1-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/dae9007f4b44df69-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/753ab63508c1917e-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/8626d83920fcb0af-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/86a64d30a633c3c8-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/989dd8fcb6c7087b-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/45b5a0869bad146c-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/4fb94b917be0ff7f-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/331d1e0b1512bdaa-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'customFont';
src: url(/_next/static/media/ea663d828d15df29-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'customFont Fallback';src: local("Arial");ascent-override: 96.26%;descent-override: 24.07%;line-gap-override: 0.00%;size-adjust: 103.88%
}.__className_d968b4 {font-family: 'customFont', 'customFont Fallback', Helvetica, ui-sans-serif
}

