@charset 'UTF-8';

.skeleton-loader {
  /* Add styles for the loader container */
}

.skeleton-item {
  margin-bottom: 20px;
}

.skeleton-title,
.skeleton-date,
.skeleton-description {
  animation: pulse 1.5s infinite;
  background-color: #f0f0f0;
  height: 20px;
  margin-bottom: 10px;
}

.skeleton-title {
  width: 70%;
}

.skeleton-date {
  width: 30%;
}

.skeleton-description {
  height: 60px;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}
