@charset 'UTF-8';

.alert,
.alert div,
.alert-icon {
  --icon-size: 20px;
}

.alert,
.alert div {
  --gap: calc(var(--gutter) / 4);
}

.alert {
  --surface-color: var(--color-white);
  --on-surface-color: var(--color-black);
  --font-size: var(--font-size-body-small);
  --position-x: var(--gutter);
  --position-y: var(--gutter);
  --min-width: 200px;
  --max-width: 414px;
  --preferred-width: 100%;

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-medium);
  color: var(--on-surface-color);
  column-gap: var(--gap);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  place-content: flex-start flex-start;
  pointer-events: none;
  position: fixed;
  right: var(--position-x);
  top: var(--position-y);
  transform: translateX(calc(100% + var(--gutter)));
  visibility: hidden;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
  z-index: 1000;
}

.alert[data-alert-is-visible="true"] {
  pointer-events: all;
  transform: translateX(0);
  transition-duration: var(--speed-extra-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-entering);
  visibility: visible;
}

.alert div {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;

  /* order: 0; */
  position: relative;
}

.alert div.icon,
.alert div.action {
  --padding: calc(var(--icon-size) / 2);

  flex-basis: var(--icon-size);
  flex-grow: 0;
  padding: var(--padding);
}

.alert div.action {
  --padding: calc(var(--icon-size) / 4);

  align-self: flex-start;
}

.alert div.message {
  padding-bottom: var(--gap);
  padding-top: var(--gap);
}

/* ----------------------------------------------- */
.alert-icon {
  --surface-color: var(--color-midnight-blue-900);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-radius: 100%;
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--icon-size);
  place-content: center center;
  width: var(--icon-size);
}

.alert-icon svg {
  fill: currentcolor;
  stroke-width: 0;
}

/* ----------------------------------------------- */
.alert-dismiss {
  /* stylelint-disable-next-line declaration-no-important */
  --surface-color: transparent !important;
  --on-surface-color: var(--color-midnight-blue-900);
}

/* ----------------------------------------------- */
.alert.success {
  --surface-color: var(--color-moss-green-600);
  --on-surface-color: var(--color-white);

  text-shadow: var(--text-shadow-moss-green);
}

.alert.success .alert-icon {
  --surface-color: var(--color-moss-green-950);
}

.alert.success .alert-dismiss {
  --on-surface-color: var(--color-moss-green-1000);
}
