@charset 'UTF-8';

.form-instructions {
  font-family: var(--font-family-quicksand-medium);
}

label,
input,
select,
button[type="submit"],
textarea {
  --font-size: var(--font-size-body-small);

  appearance: none;
  font-size: var(--font-size);
  line-height: var(--type-scale-perfect-fourth);
}

textarea {
  --default-height: calc(var(--font-size) * 6);

  height: var(--default-height);
  resize: vertical;
}

/* ----------------------------------------------- */
label,
label em {
  color: var(--color-cobalt-blue-700);
  display: block;
  font-family: var(--font-family-quicksand-bold);
}

label {
  position: relative;
}

label em {
  font-style: normal;
  margin-bottom: calc(var(--font-size) * 0.5);
}

.label-for-dropdown::after {
  --arrow-size: 7px;
  --reset-size: 0;
  --reset-color: transparent;
  --arrow-color: var(--color-black);
  --arrow-style: solid;
  --position-y: calc(50% - var(--arrow-size) / 2);
  --position-x: calc(var(--arrow-size) * 1.25);
  --vertical-offset: calc(var(--font-size) * 0.85);

  border-left: var(--arrow-size) var(--arrow-style) var(--reset-color);
  border-right: var(--arrow-size) var(--arrow-style) var(--reset-color);
  border-top: var(--arrow-size) var(--arrow-style) var(--arrow-color);
  content: "";
  display: block;
  height: var(--reset-size);
  pointer-events: none;
  position: absolute;
  right: var(--position-x);
  top: var(--position-y);
  transform: translateY(var(--vertical-offset));
  width: var(--reset-size);
}

.label-for-checkbox {
  align-items: center;
  column-gap: calc(var(--gutter) / 4);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  place-content: flex-start flex-start;
}

/* ----------------------------------------------- */
input,
select,
textarea {
  --border-width: 1px;
  --surface-color: var(--color-white);
  --on-surface-color: var(--color-black);
  --padding: calc(var(--font-size) * 0.75);

  background-color: var(--surface-color);
  border-color: var(--on-surface-color);
  border-radius: 0;
  border-style: solid;
  border-width: var(--border-width);
  color: var(--on-surface-color);
  display: inline-flex;
  font-family: var(--font-family-quicksand-medium);
  outline-color: transparent;
  outline-style: solid;
  outline-width: calc(var(--border-width) * 4);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  transition-duration: var(--speed-fast);
  transition-property: outline-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: 100%;
}

input[type="checkbox"] {
  --size: 24px;
  --inner-size: calc(var(--size) * 0.6);
  --inner-color: transparent;

  background-color: transparent;
  background-image: linear-gradient(var(--inner-color), var(--inner-color));
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: var(--inner-size) var(--inner-size);
  height: var(--size);
  width: var(--size);
}

input:hover,
select:hover,
textarea:hover {
  border-color: var(--color-cobalt-blue-600);
}

input:focus,
select:focus,
textarea:focus {
  border-color: var(--color-cobalt-blue-900);
  outline-color: var(--color-cobalt-blue-100);
  transition-duration: var(--speed-extra-fast);
}

input[type="checkbox"]:checked {
  --inner-color: var(--color-cobalt-blue-800);

  background-color: var(--color-cobalt-blue-050);
}

button[type="submit"] {
  --font-size: var(--font-size-body);
  --padding: calc(var(--font-size) * 0.75);
  --surface-color: var(--color-cobalt-blue-600);
  --on-surface-color: var(--color-white);

  background-color: var(--surface-color);
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  display: inline-flex;
  font-family: var(--font-family-havelock-black);
  font-size: var(--font-size);
  line-height: var(--font-size);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  text-shadow: var(--text-shadow-cobalt-blue);
  transition-duration: var(--speed-extra-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity),
    var(--easing-color-or-opacity);
}

button[type="submit"]:hover {
  --surface-color: var(--color-cobalt-blue-050);
  --on-surface-color: var(--color-cobalt-blue-900);

  border-color: var(--color-cobalt-blue-900);
  box-shadow: var(--shadow-elevation-medium);
  text-shadow: var(--text-shadow-white);
}

button[type="submit"]:active,
button[type="submit"]:focus {
  --surface-color: var(--color-moss-green-700);
  --on-surface-color: var(--color-white);

  border-color: transparent;
  box-shadow: none;
  text-shadow: none;
  transition-property: none;
}

/* ----------------------------------------------- */
form [role="alert"] {
  color: var(--color-error);
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: calc(var(--gutter) / 4);
}

/* ----------------------------------------------- */
.contact-form,
.contact-form-cell {
  --gap: var(--gutter);
  --cell-width: 50%;
}

.contact-form {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  margin-bottom: var(--gutter);
  margin-top: var(--gutter);
  place-content: flex-start flex-start;
}

.contact-form-cell {
  align-self: auto;
  flex-basis: calc(var(--cell-width) - var(--gap));
  flex-grow: 1;
  flex-shrink: 1;
}

.contact-form-cell-message,
.contact-form-cell-subscribe {
  flex-basis: 100%;
}
