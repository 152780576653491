@charset 'UTF-8';

.dialog,
.dialog-cell,
.dialog .header {
  --gap: calc(var(--gutter) / 2);
  --hero-image-width: calc(100% / 2.5);
}

.dialog {
  --width: 360px;
  --position-x: calc(50% - var(--width) / 2);
  --position-y: 25vh;
  --surface-color: var(--color-midnight-blue-100);
  --on-surface-color: var(--color-midnight-blue-1000);

  align-items: stretch;
  background-color: var(--surface-color);
  box-shadow: var(--shadow-elevation-high);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size-body-small);
  gap: 0 var(--gap);
  left: var(--position-x);
  line-height: var(--type-scale-perfect-fourth);
  opacity: 0;
  place-content: flex-start flex-start;
  pointer-events: none;
  position: fixed;
  text-shadow: var(--text-shadow-white);
  top: var(--position-y);
  width: var(--width);
  z-index: 1000;
}

.dialog[data-is-visible="false"] {
  transition-duration: var(--speed-extra-fast), var(--speed-fast);
  transition-property: opacity, transform;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-exiting);
}

.dialog[data-is-visible="true"] {
  opacity: 1;
  pointer-events: auto;
  transition-duration: var(--speed-fast), var(--speed-fast);
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-entering);
}

.dialog[data-anchor-position="bottom"] {
  --position-y: 20vh;

  bottom: var(--position-y);
  top: auto;
  transform: translateY(100%);
}

.dialog[data-anchor-position="bottom"][data-is-visible="true"] {
  transform: translateY(0);
}

/* ----------------------------------------------- */
.dialog .header,
.dialog-cell-content,
.close-dialog {
  --close-dialog-button-size: 22px;
  --padding-horizontal: calc(var(--gutter) / 3);
}

.dialog-cell,
.dialog-cell-image a {
  position: relative;
}

.dialog-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.dialog-cell-image {
  flex-basis: var(--hero-image-width);
  flex-grow: 0;
  flex-shrink: 0;
  order: 0;
  overflow: hidden;
  width: var(--hero-image-width);
}

.dialog .header {
  --underline-color: var(--color-white);

  border-bottom: 1px solid var(--underline-color);
  color: unset;
  font-size: var(--font-size-body-big-fluid);
  margin-bottom: 0;
  margin-top: var(--gap);
  padding-bottom: calc(var(--gap) / 2);
  padding-right: calc(var(--close-dialog-button-size) * 1.5);
}

.dialog p {
  font-size: unset;
}

.dialog-cell-content {
  flex-basis: calc(100% - var(--hero-image-width) - var(--gap));
  padding-bottom: calc(var(--gap) * 2);
  padding-right: var(--gap);
}

/* ----------------------------------------------- */
.dialog-cell-image a {
  display: block;
  height: 100%;
}

.dialog-cell-image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: top;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
}

/* ----------------------------------------------- */
img.dialog-image-landscape {
  display: none;
}

/* ----------------------------------------------- */
.close-dialog::after,
.close-dialog::before {
  --on-surface-color: var(--color-white);
  --height: 2px;
  --width: calc(100% - var(--height) * 4);
  --position-x: calc(50% - var(--width) / 2);
  --position-y: calc(50% - var(--height) / 2);

  background-color: var(--on-surface-color);
  content: "";
  height: var(--height);
  left: var(--position-x);
  position: absolute;
  top: var(--position-y);
  transform: rotate(45deg);
  transform-origin: center;
  width: var(--width);
}

.close-dialog::before {
  transform: rotate(-45deg);
}

.close-dialog {
  --position-xy: calc(var(--padding-horizontal) / 2);
  --surface-color: var(--color-black);

  background-color: var(--surface-color);
  color: transparent;
  height: var(--close-dialog-button-size);
  overflow: hidden;
  position: absolute;
  right: var(--position-xy);
  top: var(--position-xy);
  transition-duration: var(--speed-extra-fast), var(--speed-fast);
  transition-property: background-color, scale;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-snap);
  width: var(--close-dialog-button-size);
}

/* .donate .close-dialog {
  --surface-color: var(--color-neon-rose-950);
} */

.close-dialog:hover {
  --surface-color: var(--color-black);

  transition-duration: var(--speed-super-fast), var(--speed-extra-fast);
}

.close-dialog:hover::after,
.close-dialog:hover::before {
  scale: 0.8;
}

/* ----------------------------------------------- */
.dialog.donate {
  --surface-color: var(--color-neon-rose-500);
  --on-surface-color: var(--color-white);

  text-shadow: var(--text-shadow-neon-rose-dark);
}

.dialog.donate .header {
  --underline-color: var(--color-neon-rose-050);
}

/* ----------------------------------------------- */
.dialog .button-link {
  --button-radius: var(--rounded-corners-small);
  --font-size: var(--font-size-body-small);
  --surface-color: var(--color-neon-rose-050);
  --on-surface-color: var(--color-neon-rose-500);

  background-color: var(--surface-color);
  border-radius: var(--button-radius);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  display: inline-block;
  font-family: var(--font-family-havelock-black);
  font-size: var(--font-size);
  line-height: var(--font-size);
  padding: calc(var(--button-radius) * 2);
  text-shadow: var(--text-shadow-white);
  transition-duration: var(--speed-fast), var(--speed-fast), var(--speed-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity),
    var(--easing-color-or-opacity);
}

.dialog .button-link:hover,
.dialog .button-link:active,
.dialog .button-link:focus {
  --surface-color: var(--color-white);
  --on-surface-color: var(--color-neon-rose-600);
}

.dialog .button-link:hover {
  box-shadow: var(--shadow-elevation-medium);
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast), var(--speed-extra-fast);
}

.dialog .button-link:active,
.dialog .button-link:focus {
  --on-surface-color: var(--color-neon-rose-500);

  box-shadow: none;
  text-shadow: none;
  transition-property: none;
}

/* ----------------------------------------------- */
@media (width <= 400px) {
  .dialog,
  .dialog-cell,
  .dialog .header {
    --hero-image-width: 100%;
  }

  .dialog {
    --width: 275px;

    flex-direction: column;
  }

  .dialog-cell-image {
    aspect-ratio: 16 / 9;
    order: 1;
  }

  img.dialog-image-portrait {
    display: none;
  }

  img.dialog-image-landscape {
    display: block;
  }

  .dialog-cell-content {
    padding-bottom: var(--gap);
    padding-left: var(--gap);
    padding-right: var(--gap);
  }
}
