/* stylelint-disable declaration-no-important */
/* stylelint-disable no-descending-specificity */
@charset 'UTF-8';

main.fullscreen {
  min-height: 100vh;
}

/* ----------------------------------------------- */
.on-page-hero-image,
.on-page-hero-image::after {
  --image-max-height: 384px;
  --image-min-height: 320px;
  --image-preferred-height: calc(100vh / 3);
}

.on-page-hero-image {
  --surface-color: var(--color-cobalt-blue-050);

  align-items: center;
  aspect-ratio: 16 / 9;
  background-color: var(--surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: var(--gutter);
  overflow: hidden;
  place-content: center center;
  position: relative;
  width: 100%;
}

.on-page-hero-image[data-variant="shallow"] {
  height: clamp(var(--image-min-height), var(--image-preferred-height), var(--image-max-height));
}

.on-page-hero-image-with-caption {
  align-items: flex-start;
  justify-content: flex-start;
}

.on-page-hero-image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  opacity: 0;
  position: absolute;
  top: var(--media-position);
  transition-duration: var(--speed-fast);
  transition-property: opacity;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--media-size);
  z-index: 0;
}

.on-page-hero-image.join img {
  object-position: top;
}

[data-is-loaded="true"] .on-page-hero-image img {
  opacity: 1;
}

.on-page-hero-image::after {
  --position-xy: 0;

  background-color: var(--color-white);
  clip-path: polygon(var(--hero-angle) 0%, 100% 0%, calc(100% - var(--hero-angle)) 100%, 0% 100%);
  content: "";
  height: 100%;
  left: var(--position-xy);
  opacity: 0.9;
  position: absolute;
  top: var(--position-xy);
  transform: translateX(-100%);
  transition-duration: var(--speed-slow), var(--speed-normal);
  transition-property: opacity, transform;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-exiting);
  width: clamp(var(--image-min-height), var(--image-preferred-height), var(--image-max-height));
  z-index: 1;
}

[data-is-loaded="true"] .on-page-hero-image::after {
  opacity: 0;
  transform: translateX(280%);
}

/* ----------------------------------------------- */
.on-page-hero-image-with-caption .caption {
  --on-surface-color: var(--color-white);
  --surface-color: var(--color-cobalt-blue-700);
  --position-xy: calc(var(--gutter) / 2);
  --caption-min-width: 250px;
  --caption-preferred-width: 25%;
  --caption-max-width: 375px;

  background-color: var(--surface-color);
  bottom: var(--position-xy);
  box-shadow: var(--shadow-elevation-medium);
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size-body-big-fluid);
  height: auto;
  left: var(--position-xy);
  line-height: var(--type-scale-perfect-fourth);
  padding-bottom: calc(var(--gutter) / 2);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: calc(var(--gutter) / 2);
  position: absolute;
  text-shadow: var(--text-shadow-cobalt-blue-dark);
  width: clamp(var(--caption-min-width), var(--caption-preferred-width), var(--caption-max-width));
  z-index: 2;
}

.on-page-hero-image-with-caption .caption[data-align="bottom-right"] {
  left: auto;
  right: var(--position-xy);
}

.on-page-hero-image-with-caption .caption cite {
  --font-size: var(--font-size-body-small-fluid);

  display: block;
  font-size: var(--font-size);
  margin-top: calc(var(--font-size) / 2);
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .on-page-hero-image-with-caption .caption {
    padding-left: calc(var(--gutter) / 2);
    padding-right: calc(var(--gutter) / 2);
  }
}

@media (width <= 375px) {
  .on-page-hero-image[data-variant="shallow"] {
    height: unset;
  }

  .on-page-hero-image {
    flex-wrap: wrap;
    overflow: visible;
  }

  .on-page-hero-image img {
    height: auto;
    left: auto;
    position: relative;
    top: auto;
    width: 100%;
  }

  .on-page-hero-image-with-caption .caption {
    bottom: auto;
    box-shadow: none;
    flex-basis: 100%;
    left: unset !important;
    position: relative;
    right: unset !important;
    width: 100%;
  }
}

/* ----------------------------------------------- */
.on-page-hero-image.stop-child-traffic {
  --image-min-height: 128px;
  --image-max-height: 192px;

  background-color: var(--color-white);
  color: var(--stop-child-traffic-red);
  justify-content: flex-start;
}

.on-page-hero-image.stop-child-traffic svg {
  fill: currentcolor;
}

/* ----------------------------------------------- */
[data-page-name="foundation"] .on-page-hero-image-with-caption .caption {
  --surface-color: var(--color-neon-rose-500);

  text-shadow: var(--text-shadow-neon-rose-dark);
}
