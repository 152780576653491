@charset 'UTF-8';

ul.social {
  align-items: center;
  column-gap: calc(var(--gutter) / 4);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-start;
}

ul.social.share-page {
  display: flex;
}

.social.share-buttons::before,
.social.follow-buttons::before {
  --font-size: var(--font-size-body-extra-small);
  --on-surface-color: var(--color-black);

  color: var(--on-surface-color);
  content: "Share";
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size);
  line-height: var(--font-size);
}

.social.follow-buttons::before {
  --on-surface-color: var(--color-white);

  content: "Follow";

  /* Optical offset to accommodate box shadow applied to the icons */
  margin-top: 2px;
}

.footer-links li,
ul.social li {
  --social-icon-size: 24px;
}

ul.social li {
  align-self: auto;
  aspect-ratio: 1 / 1;
  flex-basis: var(--social-icon-size);
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--social-icon-size);
  position: relative;
  width: var(--social-icon-size);
}

ul.social.share-page li {
  --social-icon-size: 22px;
}

ul.social a,
ul.social button {
  --size: 100%;
  --surface-color: var(--color-cobalt-blue-800);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--size);
  place-content: center center;
  text-decoration: none;
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: var(--size);
}

[data-color-scheme="neon-rose"] ul.social a,
[data-color-scheme="neon-rose"] ul.social button {
  --surface-color: var(--color-neon-rose-500);
}

.bottom-app-bar ul.social a,
.bottom-app-bar ul.social button {
  box-shadow: var(--shadow-elevation-low);
}

ul.social svg {
  --freespace-margin: 10px;
  --size: calc(100% - var(--freespace-margin));
  --position-xy: calc(50% - (var(--size) / 2));

  aspect-ratio: 1 / 1;
  fill: currentcolor;
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
}

ul.social .tiktok svg {
  --freespace-margin: 6px;
}

ul.social .facebook svg {
  --freespace-margin: 8px;
}

/* ----------------------------------------------- */
ul.social a:hover,
ul.social button:hover {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-1000);

  transition-timing-function: var(--easing-entering), var(--easing-entering);
}

ul.social a:active,
ul.social a:focus,
ul.social button:active,
ul.social button:focus {
  --surface-color: var(--color-moss-green-300);
  --on-surface-color: var(--color-moss-green-1000);

  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
  transition-property: none;
}

.bottom-app-bar ul.social a:hover,
.bottom-app-bar ul.social button:hover {
  box-shadow: var(--shadow-elevation-medium);
}

[data-color-scheme="neon-rose"] ul.social a:hover,
[data-color-scheme="neon-rose"] ul.social button:hover {
  --surface-color: var(--color-neon-rose-200);
  --on-surface-color: var(--color-neon-rose-900);
}
