/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
@charset 'UTF-8';

.organization-registration-details {
  --font-size: var(--font-size-body-extra-small);

  align-items: flex-start;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-quicksand-book);
  font-size: var(--font-size);
  gap: calc(var(--font-size) / 2);

  /* line-height: var(--font-size); */
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: 0;
  place-content: flex-start flex-start;
}

.organization-registration-details dt,
.organization-registration-details dd {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

.organization-registration-details dt::after {
  content: ":";
}

/* ----------------------------------------------- */
dl.team-member-details,
dl.team-member-details dd {
  --gap: calc(var(--gutter) / 2);
}

dl.team-member-details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: var(--gap);
  padding-left: var(--gap);
  padding-right: var(--gap);
  padding-top: var(--gap);
  place-content: flex-start flex-start;
  row-gap: calc(var(--gap) / 2);
}

dl.team-member-details dt {
  display: none;
}

dl.team-member-details dd {
  --font-size: var(--font-size-body-fluid);

  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  width: 100%;
}

dl.team-member-details dd.name {
  --font-size: var(--font-size-h6-fluid);

  font-family: var(--font-family-quicksand-bold);
}

dl.team-member-details dd.job-title {
  line-height: var(--type-scale-major-second);
}

dl.team-member-details dd.about,
dl.team-member-details dd.social-networks {
  --font-size: var(--font-size-body-small);
}

dl.team-member-details dd.about {
  flex-grow: 1;
  line-height: var(--type-scale-golden-ratio);
}

dl.team-member-details dd.social-networks {
  --ruler-height: 1px;
  --ruler-opacity: calc(100% / 3);

  border-color: rgb(255 255 255 / var(--ruler-opacity));
  border-style: solid;
  border-width: var(--ruler-height) 0 0;
  padding-top: calc(var(--gap) / 2 + var(--ruler-height));
}

/* ----------------------------------------------- */
dl.team-member-details ul.social a,
dl.team-member-details ul.social button {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-950);

  box-shadow: var(--shadow-elevation-low);
}

dl.team-member-details ul.social a:hover,
dl.team-member-details ul.social button:hover {
  --surface-color: var(--color-cobalt-blue-100);
  --on-surface-color: var(--color-cobalt-blue-1000);

  box-shadow: var(--shadow-elevation-medium);
}

dl.team-member-details ul.social a:active,
dl.team-member-details ul.social a:focus,
dl.team-member-details ul.social button:active,
dl.team-member-details ul.social button:focus {
  --surface-color: var(--color-moss-green-300);
  --on-surface-color: var(--color-moss-green-1000);
}

dl.team-member-details ul.social li.link-to-bio-page {
  aspect-ratio: unset;
  flex-basis: auto;
  flex-grow: 1;
  height: auto;
  text-align: right;
  width: auto;
}

dl.team-member-details ul.social li.link-to-bio-page a,
dl.team-member-details ul.social li.link-to-bio-page a::after {
  --arrow-size: 6px;
}

dl.team-member-details ul.social li.link-to-bio-page a {
  --on-surface-color: var(--color-white);
  --surface-color: transparent;
  --size: auto;
  --vertical-offset: calc(var(--arrow-size) / 2);

  box-shadow: none;
  display: inline-flex;
  justify-content: flex-end;

  /* Optical vertical offset */
  margin-top: var(--vertical-offset);
  padding-right: calc(var(--arrow-size) * 2);
  position: relative;
}

dl.team-member-details ul.social li.link-to-bio-page a::after {
  --arrow-color: var(--color-cobalt-blue-200);
  --arrow-reset-size: 0;
  --arrow-position-x: 0;

  border-bottom: var(--arrow-size) solid transparent;
  border-left: var(--arrow-size) solid var(--arrow-color);
  border-top: var(--arrow-size) solid transparent;
  content: "";
  height: var(--arrow-reset-size);
  position: absolute;
  right: 0;
  top: auto;
  transform: translateX(var(--arrow-position-x));
  transition-duration: var(--speed-extra-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-exiting);
  width: var(--arrow-reset-size);
}

dl.team-member-details ul.social li.link-to-bio-page a:hover::after,
dl.team-member-details ul.social li.link-to-bio-page a:active::after,
dl.team-member-details ul.social li.link-to-bio-page a:focus::after {
  --arrow-position-x: calc(var(--arrow-size) / 2);
}

dl.team-member-details ul.social li.link-to-bio-page a:hover::after {
  --arrow-color: var(--color-white);

  transition-timing-function: var(--easing-entering);
}

dl.team-member-details ul.social li.link-to-bio-page a:active::after,
dl.team-member-details ul.social li.link-to-bio-page a:focus::after {
  --arrow-color: var(--color-moss-green-300);

  transition-property: none;
}

/* ----------------------------------------------- */
.team-list {
  --vertical-margin: calc(var(--gutter) / 2);

  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  place-content: stretch flex-start;
}

.team-list li {
  align-self: auto;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
}

.team-list-team-member-container {
  --surface-color: var(--color-midnight-blue-600);
  --on-surface-color: var(--color-white);
  --text-shadow-color: var(--text-shadow-midnight-blue-dark);

  align-items: stretch;
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  place-content: stretch flex-start;
  text-shadow: var(--text-shadow-color);
}

.team-list li:nth-child(3) .team-list-team-member-container,
.team-list li:nth-child(4) .team-list-team-member-container {
  --surface-color: var(--color-midnight-blue-800);
}

.team-list-team-member-cell {
  align-self: auto;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.team-list-team-member-cell.image {
  /* aspect-ratio: 1 / 1; */
  background-color: var(--color-white);
  order: 0;
}

.team-list li:nth-child(3) .team-list-team-member-container .team-list-team-member-cell.image,
.team-list li:nth-child(4) .team-list-team-member-container .team-list-team-member-cell.image {
  order: 1;
}

.team-list-team-member-cell.image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

@media (width <= 768px) {
  .team-list li:not(.team-list ul.social li) {
    border-bottom: 1px solid var(--color-white);
    flex-basis: 100%;
  }

  .team-list li:nth-child(3) .team-list-team-member-container .team-list-team-member-cell.image,
  .team-list li:nth-child(4) .team-list-team-member-container .team-list-team-member-cell.image {
    order: 0;
  }

  dl.team-member-details dd.social-networks {
    --ruler-height: 0;
  }

  .team-list li:nth-child(2) .team-list-team-member-container,
  .team-list li:nth-child(3) .team-list-team-member-container,
  .team-list li:nth-child(4) .team-list-team-member-container,
  .team-list li:nth-child(6) .team-list-team-member-container {
    --surface-color: var(--color-midnight-blue-600);
  }
}

@media (width <= 480px) {
  .team-list-team-member-container {
    flex-wrap: wrap;
  }

  .team-list-team-member-cell.image {
    flex-basis: 25%;
  }
}

@media (width <= 430px) {
  .team-list {
    row-gap: calc(var(--gutter) / 6);
  }

  .team-list li {
    background-color: var(--color-midnight-blue-600);
    border-bottom: none;
  }

  .team-list-team-member-container {
    align-items: flex-start;
    column-gap: calc(var(--gutter) / 8);
    flex-wrap: nowrap;
  }

  .team-list-team-member-cell.image {
    background-color: var(--color-midnight-blue-600);
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .team-list-team-member-cell {
    flex-basis: auto;
  }

  .team-list-team-member-cell.image img {
    --media-size: 100px;

    background-color: var(--color-white);
    left: unset;
    margin-left: calc(var(--gutter) / 4);
    margin-top: calc(var(--gutter) / 4);
    position: relative;
    top: unset;
  }
}

/* ----------------------------------------------- */
.partners-list,
.partners-list li {
  --gap: calc(var(--gutter) * 2);
}

@media (width <= 768px) {
  .partners-list,
  .partners-list li {
    --gap: var(--gutter);
  }
}

.partners-list {
  --vertical-margin: calc(var(--gutter) / 2);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--gap) var(--gap);
  list-style: none;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  place-content: stretch space-between;
  text-align: center;
}

.partners-list li {
  --cell-width: calc(100% / 3 - var(--gap));

  align-self: auto;
  aspect-ratio: 2 / 1;
  border-color: var(--color-midnight-blue-100);
  border-style: solid;
  border-width: 1px;
  flex-basis: var(--cell-width);
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.partners-list li img {
  --media-size: calc(90% - var(--gap));
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .partners-list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .partners-list li {
    --cell-width: calc(50% - var(--gap));

    flex-grow: 0;
    flex-shrink: 0;
  }
}

@media (width <= 375px) {
  .partners-list {
    row-gap: calc(var(--gap) / 2);
  }

  .partners-list li {
    --cell-width: 100%;
  }

  .partners-list li img {
    --media-size: 140px;
  }
}

/* ----------------------------------------------- */
.key-aspects {
  --vertical-margin: var(--gutter);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  place-content: flex-start flex-start;
  row-gap: 2px;
}

.key-aspects li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.key-aspects dl,
.key-aspects dt,
.key-aspects dd {
  --term-width: 25%;
  --term-minimum-width: 20ch;
}

.key-aspects dl {
  align-items: stretch;

  /* column-gap: var(--gutter); */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 0;
  margin-top: 0;
  place-content: stretch flex-start;
}

.key-aspects dt,
.key-aspects dd {
  --surface-color: var(--color-cobalt-blue-700);
  --on-surface-color: var(--color-white);
  --padding: calc(var(--gutter) / 4);

  align-self: auto;
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: calc(var(--padding) * 2);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  text-shadow: var(--text-shadow-cobalt-blue-dark);
}

.key-aspects dt {
  flex-basis: var(--term-width);
  flex-shrink: 0;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-body-big-fluid);
  min-width: var(--term-minimum-width);
  padding-left: calc(var(--padding) * 2);

  /* white-space: nowrap; */
  width: var(--term-width);
}

.key-aspects dd {
  --surface-color: var(--color-cobalt-blue-050);
  --on-surface-color: var(--color-cobalt-blue-1000);

  font-family: var(--font-family-quicksand-medium);
  line-height: var(--type-scale-golden-ratio);
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 4);
  padding-top: calc(var(--padding) + 0.05em);
  text-shadow: var(--text-shadow-white);
}

.key-aspects dt.alternate {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-900);

  text-shadow: var(--text-shadow-white);
}

.key-aspects dd.alternate {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-1000);

  padding-left: 0;

  /* padding-left: var(--padding); */
  padding-right: var(--padding);
  padding-top: var(--padding);
}

@media (width <= 540px) {
  .key-aspects dl,
  .key-aspects dt,
  .key-aspects dd {
    --term-width: 100%;
    --term-minimum-width: 20ch;
  }

  .key-aspects dl {
    flex-wrap: wrap;
  }

  .key-aspects dt {
    padding-bottom: var(--padding);
  }

  .key-aspects dd.alternate {
    padding-right: 0;
    padding-top: 0;
  }

  .key-aspects dd.alternate .link-with-image {
    margin-left: calc(var(--gutter) / 2);
    margin-right: calc(var(--gutter) / 2);
  }
}

/* ----------------------------------------------- */
.advisers-list {
  --gap: calc(var(--gutter) / 2);

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  list-style: none;
  margin-bottom: var(--gap);
  margin-top: var(--gap);
  padding: 0;
  place-content: flex-start flex-start;
}

/* @media (width <= 540px) {
  .advisers-list {
    justify-content: space-between;
  }
} */

.advisers-list li {
  --item-width: calc(25% - var(--gap));

  align-self: auto;
  flex-basis: var(--item-width);
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 128px;
}

@media (width <= 768px) {
  .advisers-list li {
    --item-width: calc(100% / 3 - var(--gap));

    max-width: unset;
  }
}

@media (width <= 540px) {
  .advisers-list li {
    --item-width: calc(50% - var(--gap));
  }
}

.advisers-list dl {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
  place-content: flex-start flex-start;
  position: relative;
  row-gap: calc(var(--gutter) / 4);
}

.advisers-list dt {
  display: none;
}

.advisers-list dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  order: 1;
  padding: 0;
}

.advisers-list dd.image {
  aspect-ratio: 1 / 1;
  background-color: var(--color-white);
  order: 0;
  position: relative;
  width: 100%;
}

.advisers-list dd.image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

.advisers-list dd.image svg {
  fill: var(--color-midnight-blue-900);
}

.advisers-list dd.name,
.advisers-list dd.name span {
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-perfect-fourth);
}

.advisers-list dd.name span {
  display: block;
}

.advisers-list dd.name a {
  color: var(--color-black);
}

.advisers-list dd.image a {
  --size: 100%;

  border-color: var(--color-black);
  border-style: solid;
  border-width: 1px;
  display: block;
  height: var(--size);
  position: relative;
  width: var(--size);
}

.advisers-list dd.image a:hover {
  border-color: var(--color-cobalt-blue-600);
}

.advisers-list dd.image a:active,
.advisers-list dd.image a:focus {
  border-color: var(--color-cobalt-blue-900);
}

.advisers-list dd.linkedin-url {
  --icon-size: 20px;
  --position-xy: 0;

  bottom: var(--position-xy);
  flex-basis: var(--icon-size);
  height: var(--icon-size);
  position: absolute;
  right: var(--position-xy);
  transform: translateY(calc(var(--icon-size) * 0.75 * -1));
  width: var(--icon-size);
}

.advisers-list dd.linkedin-url a {
  --surface-color: var(--color-cobalt-blue-100);
  --on-surface-color: var(--color-cobalt-blue-700);
  --size: 100%;

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--size);
  place-content: center center;
  position: relative;
  text-decoration: none;
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: var(--size);
}

dd.linkedin-url svg {
  --freespace-margin: 8px;
  --size: calc(100% - var(--freespace-margin));
  --position-xy: calc(50% - (var(--size) / 2));

  aspect-ratio: 1 / 1;
  fill: currentcolor;
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
}

/* ----------------------------------------------- */
.advisers-list dd.linkedin-url a:hover {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-900);

  transition-timing-function: var(--easing-entering), var(--easing-entering);
}

.advisers-list dd.linkedin-url a:active,
.advisers-list dd.linkedin-url a:focus {
  --surface-color: var(--color-moss-green-300);
  --on-surface-color: var(--color-moss-green-1000);

  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
  transition-property: none;
}

/* ----------------------------------------------- */
.rss-feed,
.feed-item,
.feed-item-cell,
.headline-with-date,
.feed-item [itemprop="description"],
.feed-item .source {
  --gap: calc(var(--gutter) / 2);
}

.rss-feed {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
  margin-top: calc(var(--gap) * 2);
  padding: 0;
  place-content: flex-start flex-start;
  row-gap: var(--gap);
}

.rss-feed li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
}

/* ----------------------------------------------- */
.feed-item,
.feed-item-cell {
  --default-image-width: 128px;
}

.feed-item {
  align-items: flex-start;
  border-bottom: 1px solid var(--color-midnight-blue-200);
  column-gap: calc(var(--gap) * 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: var(--gap);
  padding-top: var(--gap);
  place-content: flex-start flex-start;
}

.rss-feed li:last-child .feed-item {
  border-bottom: none;
}

.feed-item-cell {
  align-self: auto;

  /* flex-basis: calc(100% - var(--default-image-width) - var(--gap)); */
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.feed-item-cell-image {
  aspect-ratio: 16 / 9;
  background-color: var(--color-midnight-blue-050);
  flex-basis: var(--default-image-width);
  flex-grow: 0;
  flex-shrink: 0;
  order: 0;
  position: relative;
}

.feed-item-cell-image a {
  /* box-shadow: var(--shadow-elevation-low); */
  display: block;
  height: 100%;

  /* transition-duration: var(--speed-extra-fast);
  transition-property: box-shadow;
  transition-timing-function: var(--easing-exiting); */
}

/* .feed-item-cell-image a:hover {
  box-shadow: var(--shadow-elevation-medium);
  transition-duration: var(--speed-fast);
} */

.feed-item-cell-image[data-is-valid-image="false"] {
  background-color: transparent;
}

.feed-item-cell-image[data-is-valid-image="false"] a {
  display: none;
}

.feed-item-cell-image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  position: absolute;
  scale: 1;
  top: var(--media-position);
  transform-origin: center;
  width: var(--media-size);

  /* z-index: 0; */
}

.feed-item-cell-image a:hover img {
  transform: scale(1.05);
  transition-duration: var(--speed-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-entering);
}

/* ----------------------------------------------- */
.feed-item .headline-with-date,
.feed-item .headline-with-date dd,
.feed-item [itemprop="description"],
.feed-item .source {
  --date-stamp-width: 12ch;
}

.feed-item .headline-with-date {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--gap) / 2) calc(var(--gap) * 2);
  margin-bottom: calc(var(--gap) * 0.5);
  margin-top: calc(var(--gap) / 2 * -1);
  place-content: flex-start flex-start;
}

.feed-item .headline-with-date dt {
  display: none;
}

.feed-item .headline-with-date dd,
.feed-item .headline-with-date dd span,
.feed-item .source {
  line-height: var(--type-scale-perfect-fourth);
}

.feed-item .headline-with-date dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.feed-item .headline-with-date dd.headline {
  flex-basis: calc(100% - var(--date-stamp-width) - var(--gap) * 2);
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-body-fluid);
}

.feed-item .headline-with-date dd.headline a,
.feed-item .source a {
  color: var(--color-cobalt-blue-900);
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.feed-item .headline-with-date dd.headline a:hover,
.feed-item .source a:hover {
  color: var(--color-cobalt-blue-700);
  transition-duration: var(--speed-extra-fast);
}

.feed-item .headline-with-date dd.headline a:active,
.feed-item .headline-with-date dd.headline a:focus,
.feed-item .source a:active,
.feed-item .source a:focus {
  color: var(--color-orange-600);
}

.feed-item .headline-with-date dd.date {
  --surface-color: var(--color-midnight-blue-050);
  --on-surface-color: var(--color-midnight-blue-900);
  --font-size: var(--font-size-body-small);
  --padding: calc(var(--font-size) / 4);

  background-color: var(--surface-color);
  color: var(--on-surface-color);
  flex-basis: var(--date-stamp-width);
  flex-grow: 0;
  flex-shrink: 0;
  font-size: var(--font-size);
  line-height: var(--font-size);

  /* Optical offset */
  margin-top: -1px;
  padding-bottom: calc(var(--padding) + 1px);
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 2);
  padding-top: var(--padding);
  text-align: center;
  text-shadow: var(--text-shadow-white);
  white-space: nowrap;
  width: var(--date-stamp-width);
}

/* ----------------------------------------------- */
.feed-item [itemprop="description"] {
  font-size: var(--font-size-body-small);
  margin-bottom: 0;
  margin-right: calc(var(--date-stamp-width) - var(--gap));
  margin-top: 0;
  padding-bottom: calc(var(--gap) / 2);
  padding-right: calc(var(--gap) * 4);
}

/* ----------------------------------------------- */
.feed-item .headline-with-date time,
.feed-item .headline-with-date [itemprop="headline"] {
  --date-stamp-width: 20%;
}

.feed-item .headline-with-date time {
  flex-basis: var(--date-stamp-width);
  flex-grow: 0;
  flex-shrink: 0;
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-small-fluid);
  white-space: nowrap;
}

.feed-item .headline-with-date [itemprop="headline"] {
  flex-basis: calc(100% - var(--date-stamp-width) - var(--gap));
  flex-grow: 1;
  flex-shrink: 1;
}

/* ----------------------------------------------- */
.feed-item .source {
  border-top: 1px solid var(--color-midnight-blue-050);
  color: var(--color-cobalt-blue-900);
  display: block;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-body-small-fluid);
  margin-right: calc(var(--date-stamp-width) + var(--gap) * 2);
  padding-bottom: calc(var(--gap) / 2);
  padding-top: calc(var(--gap) / 2);
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .feed-item [itemprop="description"],
  .feed-item .source {
    margin-right: 0;
    padding-right: 0;
  }

  .feed-item .headline-with-date {
    margin-bottom: calc(var(--gap) * 1);
  }
}

@media (width <= 540px) {
  .feed-item,
  .feed-item-cell {
    --default-image-width: 100px;
  }

  .feed-item .headline-with-date {
    flex-wrap: wrap;
  }

  .feed-item .headline-with-date dd.headline {
    flex-basis: 100%;
  }
}

@media (width <= 480px) {
  .feed-item [itemprop="description"] {
    display: none;
  }

  .feed-item {
    column-gap: var(--gap);
    padding-bottom: calc(var(--gap) / 2);
    padding-top: calc(var(--gap) / 2);
  }

  .feed-item .headline-with-date {
    margin-top: calc(var(--gap) * 0.25 * -1);
  }
}

@media (width <= 375px) {
  .feed-item-cell-image {
    display: none;
  }
}

/* ----------------------------------------------- */
ol[itemtype="https://schema.org/BreadcrumbList"],
ol[itemtype="https://schema.org/BreadcrumbList"] a
{
  --on-surface-color: var(--color-midnight-blue-950);

  color: var(--on-surface-color);
  text-shadow: var(--text-shadow-white);
}

ol[itemtype="https://schema.org/BreadcrumbList"],
ol[itemtype="https://schema.org/BreadcrumbList"] span,
ol[itemtype="https://schema.org/BreadcrumbList"] li
{
  --font-size: var(--font-size-body-extra-small);
  --gap: calc(var(--font-size) * 1.5);

  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size);
  line-height: var(--font-size);
}

ol[itemtype="https://schema.org/BreadcrumbList"]
{
  --padding: calc(var(--gap) / 3);
  --surface-color: var(--color-midnight-blue-050);
  --vertical-offset: calc(var(--gap) * 2.25 * -1);

  align-items: center;
  background-color: var(--surface-color);

  /* border-bottom: 2px solid var(--color-midnight-blue-200); */
  column-gap: var(--gap);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: auto;
  list-style: none;
  margin-bottom: var(--gap);
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  place-content: flex-start flex-start;
  position: absolute;
  top: auto;
  transform: translateY(var(--vertical-offset));
}

@media (width <= 768px) {
  ol[itemtype="https://schema.org/BreadcrumbList"]
  {
    --vertical-offset: 0;

    position: relative;
  }
}

ol[itemtype="https://schema.org/BreadcrumbList"] li
{
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

ol[itemtype="https://schema.org/BreadcrumbList"] li:first-child
{
  display: none;
}

ol[itemtype="https://schema.org/BreadcrumbList"] li:not(:last-child):after
{
  content: "/";
  position: absolute;
  right: calc(var(--gap) * 0.7 * -1);
}

ol[itemtype="https://schema.org/BreadcrumbList"] [aria-current="page"] a
{
  pointer-events: none;
}

/* ----------------------------------------------- */
.next-and-previous-page-in-section-navigation,
.next-and-previous-page-in-section-navigation a,
.next-and-previous-page-in-section-navigation a::before,
.next-and-previous-page-in-section-navigation a::after {
  --on-surface-color: var(--color-white);

  color: var(--on-surface-color);
}

.next-and-previous-page-in-section-navigation {
  --surface-color: var(--color-midnight-blue-700);
  --font-size: var(--font-size-body-small);

  align-items: center;
  background-color: var(--surface-color);
  column-gap: calc(var(--gutter) * 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
  place-content: center space-between;
}

.next-and-previous-page-in-section-navigation li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.next-and-previous-page-in-section-navigation a,
.next-and-previous-page-in-section-navigation a::after,
.next-and-previous-page-in-section-navigation a::before {
  --arrow-width: 76px;
}

.next-and-previous-page-in-section-navigation a {
  --horizontal-margin: calc(var(--gutter) / 4);
  --horizontal-padding: calc(var(--arrow-width) / 3);
  --padding-vertical: calc(var(--gutter) / 3);

  display: inline-block;
  padding-bottom: var(--padding-vertical);
  padding-top: var(--padding-vertical);
  position: relative;
  text-shadow: var(--text-shadow-midnight-blue-dark);
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.next-and-previous-page-in-section-navigation a:hover {
  --on-surface-color: var(--color-cobalt-blue-400);

  transition-duration: var(--speed-extra-fast);
}

.next-and-previous-page-in-section-navigation a:hover::after,
.next-and-previous-page-in-section-navigation a:hover::before {
  --on-surface-color: var(--color-cobalt-blue-100);
}

.next-and-previous-page-in-section-navigation a:active,
.next-and-previous-page-in-section-navigation a:focus {
  --on-surface-color: var(--color-white);

  text-shadow: none;
  transition-property: none;
}

.next-and-previous-page-in-section-navigation a::after,
.next-and-previous-page-in-section-navigation a::before {
  --arrow-height: 73.5px;
  --arrow-x-offset: 0.4;
  --position-y: calc(50% - var(--arrow-height) / 2);

  background-color: var(--on-surface-color);
  display: block;
  height: var(--arrow-height);
  position: absolute;
  top: var(--position-y);
  transform: scale(0.2);
  width: var(--arrow-width);
}

.next-and-previous-page-in-section-navigation a[rel="prev"] {
  margin-left: var(--horizontal-margin);
  padding-left: var(--horizontal-padding);
}

.next-and-previous-page-in-section-navigation a[rel="next"] {
  margin-right: var(--horizontal-margin);
  padding-right: var(--horizontal-padding);
}

.next-and-previous-page-in-section-navigation a[rel="prev"]::before {
  clip-path: polygon(0 50%, 48.4% 0, 48.4% 31%, 100% 31%, 100% 69%, 48.4% 69%, 48.4% 100%);
  content: "";
  left: calc(var(--arrow-width) * var(--arrow-x-offset) * -1);
}

.next-and-previous-page-in-section-navigation a[rel="next"]::after {
  clip-path: polygon(0 31%, 51.6% 31%, 51.6% 0, 100% 50%, 51.6% 100%, 51.6% 69%, 0 69%);
  content: "";
  right: calc(var(--arrow-width) * var(--arrow-x-offset) * -1);
}

@media (width <= 480px) {
  .next-and-previous-page-in-section-navigation li.previous {
    display: none;
  }

  .next-and-previous-page-in-section-navigation {
    justify-content: flex-end;
  }
}

/* ----------------------------------------------- */
.team-picture-quilt {
  --gap: 0;

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  height: 100%;
  list-style: none;
  margin-bottom: calc(var(--gutter) / 2);
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
  place-content: flex-start flex-start;
}

.team-picture-quilt li {
  --picture-width: 25%;

  align-self: auto;
  aspect-ratio: 4 / 9;
  flex-basis: var(--picture-width);
  flex-grow: 1;
  flex-shrink: 1;
  height: 50%;
  position: relative;
  width: var(--picture-width);
}

@media (width <= 768px) {
  .team-picture-quilt li {
    --picture-width: calc(100% / 3);
  }
}
